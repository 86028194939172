@import 'assets/stylesheets/resources/fonts', 'assets/stylesheets/resources/colors';

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

@mixin textSubheaderLabel {
  text-transform: uppercase;
  color: $colorLynch;
  line-height: 16px;
}

@mixin textContentLabel {
  font-style: normal;
  line-height: 15px;
  color: $colorRiverRed;
}

@mixin dataRow {
  margin-bottom: 20px;

  &Content {
    background: $colorAthensGray;
    border-radius: 4px;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    &--clear {
      background: none;
      border-radius: 0;
      padding: 0;
      display: block;
    }

    &--flex {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    & > * {
      margin-right: 20px;
    }
  }
}

@mixin disableAutoFill {
  -webkit-box-shadow: 0 0 0 9999px white inset !important;
}

@mixin ellipsisText {
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin tableActions {
  fill: $colorLynch;
  cursor: pointer;
  display: block;
  margin-left: -4px;
  padding: 0 4px;
  border-radius: 4px;
  width: 24px;
  height: 24px;

  &:hover {
    background: $colorMystic;
    fill: $colorRiverRed;
  }

  &.ant-dropdown-open,
  &--active,
  &:active {
    background: $colorPrimaryBlue;
    fill: $colorWhite;
    &:hover {
      background: $colorPrimaryBlue;
      fill: $colorWhite;
    }
  }
}

@mixin iconColors {
  fill: $colorLynch;
  cursor: pointer;
  display: block;

  &:hover {
    fill: $colorRiverRed;
  }
}

@mixin panelAccordion {
  border-bottom: 0 !important;

  > .ant-collapse-header {
    color: $colorLynch !important;
    background-color: $colorMystic;
    padding: 2px 20px !important;
    line-height: 16px;
    border: 0;

    .ant-collapse-arrow {
      padding-top: 0 !important;
    }
  }

  > .ant-collapse-content {
    background: #fff;
    border: 0;
    padding: 0;

    > .ant-collapse-content-box {
      padding: 20px;
    }
  }

  > .ant-collapse-item {
    border: 0;
  }

  &NoMargin {
    > .ant-collapse-content {
      > .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
}

@mixin panelAccordionIcon {
  .ant-collapse {
    .ant-collapse-header {
      .ant-collapse-arrow {
        vertical-align: -2px !important;
      }

      svg {
        fill: $colorLynch;
        height: 12px;
        transform: rotate(180deg) !important;
        width: 7px;
      }
    }

    > .ant-collapse-item-active {
      .ant-collapse-header {
        svg {
          transform: rotate(270deg) !important;
        }
      }
    }
  }
}

@mixin panelActionsWrapper {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  row-gap: 10px;

  &-action {
    text-align: center;
  }
}

@mixin panelActionButton {
  $root: &;

  border-radius: 4px;
  border: 0;
  box-shadow: none;
  color: $colorLynch;
  height: auto;
  max-width: 100%;
  min-width: 100%;
  padding: 10px 0;
  text-align: center;
  white-space: initial;
  word-break: break-word;

  &[disabled] {
    background: transparent !important;
    color: $colorLynch;
    opacity: 0.5;

    &:hover {
      color: $colorLynch;

      > span {
        color: $colorLynch;
      }

      svg {
        fill: $colorLynch;
      }
    }
  }

  &:focus {
    color: $colorLynch;
  }

  &Icon {
    align-items: center;
    border-radius: 100%;
    border: 1px solid $colorSpindle;
    display: flex;
    height: 32px;
    justify-content: center;
    margin: 0 auto;
    width: 32px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  > span {
    color: $colorLynch;
    display: block;
    font-size: $fontSizeXXSmall;
    font-weight: $fontWeightMedium;
    line-height: 14px;
    margin-top: 5px;
    text-align: center;
  }

  &:not([disabled]):hover {
    color: $colorRiverRed;

    #{$root} {
      &Icon {
        border-color: $colorCornflowerBlue;
      }
    }

    svg {
      fill: $colorRiverRed;
    }

    > span {
      color: $colorRiverRed;
    }

    .MapButton-hoverText {
      top: calc(100% - 10px);
      left: -20px;
    }
  }

  &--active {
    #{$root} {
      &Icon {
        background-color: $colorPrimaryBlue;
      }
    }

    svg {
      fill: $colorWhite;
    }

    > span {
      color: $colorPrimaryBlue;
    }

    .MapButton-hoverText {
      top: calc(100% - 10px);
      left: -20px;
    }

    &:hover {
      #{$root} {
        &Icon {
          background-color: $colorCeruleanBlue;
        }
      }

      svg {
        fill: $colorWhite !important;
      }

      > span {
        color: $colorCeruleanBlue;
      }
    }
  }
}

@mixin inputBorder {
  border-radius: 4px;
  border: 1px solid $colorSpindle;
}

@mixin adminSelectTitle {
  color: $colorLynch;
  font-style: normal;
  line-height: 19px;
  margin-right: 10px;
  text-transform: uppercase;
}

@mixin fontStyles($fontWeight, $fontSize, $fontColor) {
  font-style: normal;
  font-weight: $fontWeight;
  font-size: $fontSize;
  line-height: $fontSize;
  color: $fontColor;
}
