$side-bar-background: #2a2e43;
$side-bar-selected: #604532;
$colorBlack: #000;
$colorTextDark: #2a3344;
$colorButtonGrey: #f8f8f8;
$colorCellOdd: #f9fbff;
$colorCello: #48515d;
$colorCuriousBlue: #4183c4;
$colorDarkBlue: #2a2e43;
$colorDarkGrey: #454f63;
$colorDownloadButton: #47507e;
$colorEbonyClay: #2b2e41;
$colorEndeavour: #1e519f;
$colorFlushOrange: #ff7f02;
$colorFlushDeepOrange: #d96c02;
$colorGainsboro: #d8d8d8;
$colorGreen: #2b9b56;
$colorGrey: #dfe6eb;
$colorGreyWarm: #c4c4c4;
$colorIrishCoffee: #604532;
$colorLemonChiffon: #fffacd;
$colorLightGrey: #f5f5f5;
$colorLighterGrey: #f0f0f0;
$colorLinkWater: #cacbd0;
$colorMutedBlack: #4d5662;
$colorMutedDarkBlue: #1f1f2b;
$colorMutedGrey: #898989;
$colorOrange: #ff8801;
$colorPrimaryBlue: #4285f4;
$colorPrimaryBlueHover: #3498db;
$colorRed: #d92424;
$colorLightRed: #fff6f6;
$colorSecondaryBlue: #6699dd;
$colorShadowLight: #929eb8;
$colorShadow: #455b63;
$colorShadowLight: #929eb8;
$colorSidebarHeader: #323544;
$colorSilver: #bcbcbc;
$colorSilverGrey: #ebe8f7;
$colorSkyBlue: #f3f8ff;
$colorSolitude: #ece8f8;
$colorSummerSky: #186572;
$colorTransparent: transparent;
$colorWhite: #fff;
$colorWhiteSmoke: #f2f2f2;
$colorZambezi: #606060;
$colorMantis: #65c466;
$colorIron: #e9e9ea;
$colorLynch: #6b7a99;
$colorLynchDark: #454e63;
$colorHawkesBlue: #dddee1;
$colorRiverRed: #465064;
$colorPomegranate: #f44336;
$colorDeepRed: #ab3a38;
$colorIron: #dededf;
$colorDodgerBlue: #3f89f7;
$colorCeruleanBlue: #2e63b2;
$colorBaliHai: #919eb8;
$colorMischka: #e3e2e5;
$colorHalfBaked: #85b7d9;
$colorShipCove: #7c93bf;
$colorAthensGray: #eff1f5;
$colorMystic: #dee3ea;
$colorMysticLight: #d9d9d9;
$colorSpindle: #b6c6e6;
$colorLightWhite: #e7ecf0;
$colorDisabledGrey: #b5b7bf;
$colorDisabledLightGrey: #f4f5f8;
$colorPeriwinkleGray: #b6c6e5;
$colorCheese: #ffbf81;
$colorRockBlue: #929eb8;
$colorStiletto: #9f3a38;
$colorCornflowerBlue: #4285f4;
$colorPingDark: #bf9105;
$colorCatskillWhite: #f2f6f9;
$colorGeyser: #ccd4de;
$colorColumbiaBlue: #ccd4de; $side-bar-background: #2a2e43;
$side-bar-selected: #604532;

$colorBlack: #000;
$colorTextDark: #2a3344;
$colorButtonGrey: #f8f8f8;
$colorCellOdd: #f9fbff;
$colorCello: #48515d;
$colorCuriousBlue: #4183c4;
$colorDarkBlue: #2a2e43;
$colorDarkGrey: #454f63;
$colorDownloadButton: #47507e;
$colorEbonyClay: #2b2e41;
$colorEndeavour: #1e519f;
$colorFlushOrange: #ff7f02;
$colorFlushDeepOrange: #d96c02;
$colorGainsboro: #d8d8d8;
$colorGreen: #2b9b56;
$colorGrey: #dfe6eb;
$colorGreyWarm: #c4c4c4;
$colorGreyCold: #6b7a994d;
$colorIrishCoffee: #604532;
$colorLemonChiffon: #fffacd;
$colorLightGrey: #f5f5f5;
$colorLighterGrey: #f0f0f0;
$colorLinkWater: #cacbd0;
$colorMutedBlack: #4d5662;
$colorMutedDarkBlue: #1f1f2b;
$colorMutedGrey: #898989;
$colorOrange: #ff8801;
$colorPrimaryBlue: #4285f4;
$colorPrimaryBlueHover: #3498db;
$colorRed: #d92424;
$colorLightRed: #fff6f6;
$colorSecondaryBlue: #6699dd;
$colorShadowLight: #929eb8;
$colorShadow: #455b63;
$colorShadowLight: #929eb8;
$colorSidebarHeader: #323544;
$colorSilver: #bcbcbc;
$colorSilverGrey: #ebe8f7;
$colorSkyBlue: #f3f8ff;
$colorSolitude: #ece8f8;
$colorSummerSky: #186572;
$colorTransparent: transparent;
$colorWhite: #fff;
$colorWhiteSmoke: #f2f2f2;
$colorZambezi: #606060;
$colorMantis: #65c466;
$colorIron: #e9e9ea;
$colorLynch: #6b7a99;
$colorLynchDark: #454e63;
$colorHawkesBlue: #dddee1;
$colorRiverRed: #465064;
$colorPomegranate: #f44336;
$colorDeepRed: #ab3a38;
$colorIron: #dededf;
$colorDodgerBlue: #3f89f7;
$colorCeruleanBlue: #2e63b2;
$colorBaliHai: #919eb8;
$colorMischka: #e3e2e5;
$colorHalfBaked: #85b7d9;
$colorShipCove: #7c93bf;
$colorAthensGray: #eff1f5;
$colorMystic: #dee3ea;
$colorMysticLight: #d9d9d9;
$colorSpindle: #b6c6e6;
$colorLightWhite: #e7ecf0;
$colorDisabledGrey: #b5b7bf;
$colorDisabledLightGrey: #f4f5f8;
$colorPeriwinkleGray: #b6c6e5;
$colorCheese: #ffbf81;
$colorRockBlue: #929eb8;
$colorStiletto: #9f3a38;
$colorCornflowerBlue: #4285f4;
$colorPingDark: #bf9105;
$colorCatskillWhite: #f2f6f9;
$colorGeyser: #ccd4de;
$colorColumbiaBlue: #ccd4de;

//Status Colors
$colorStatusStopped: #d92424;
$colorStatusMoving: #2b9b56;
$colorStatusSpeeding: #ffeb3b;
$colorStatusIdle: #607d8b;
$colorStatusPowerOff: #00b7db;
$colorStatusHarshBraking: #d15b50;
$colorStatusCornering: #ba9600;
$colorStatusAssetCheck: #daa520;
$colorStatusTowing: #e91e63;
$colorStatusSuspended: #9c27b0;

// New status colors
$colorEventsStatusMoving: #4caf50;
$colorEventsStatusStopped: #f44336;
$colorEventsStatusIdle: #607d8b;
$colorEventsStatusSpeeding: #ffc107;
$colorEventsStatusAssetCheckIn: #ff9800;
$colorEventsStatusTowing: #e91e63;
$colorEventsStatusSuspended: #9c27b0;
$colorEventsStatusCornering: #ff5722;
$colorEventsStatusBraking: #ff5722;
$colorEventsStatusInput: #03a9f4;
$colorStatusStopped: #d92424;
$colorStatusMoving: #2b9b56;
$colorStatusSpeeding: #ffeb3b;
$colorStatusIdle: #607d8b;
$colorStatusPowerOff: #00b7db;
$colorStatusHarshBraking: #d15b50;
$colorStatusCornering: #ba9600;
$colorStatusAssetCheck: #daa520;
$colorStatusTowing: #e91e63;
$colorStatusSuspended: #9c27b0; $side-bar-background: #2a2e43;
$side-bar-selected: #604532;

$colorBlack: #000;
$colorTextDark: #2a3344;
$colorButtonGrey: #f8f8f8;
$colorCellOdd: #f9fbff;
$colorCello: #48515d;
$colorCuriousBlue: #4183c4;
$colorDarkBlue: #2a2e43;
$colorDarkGrey: #454f63;
$colorDownloadButton: #47507e;
$colorEbonyClay: #2b2e41;
$colorEndeavour: #1e519f;
$colorFlushOrange: #ff7f02;
$colorFlushDeepOrange: #d96c02;
$colorGainsboro: #d8d8d8;
$colorGreen: #2b9b56;
$colorGrey: #dfe6eb;
$colorGreyWarm: #c4c4c4;
$colorIrishCoffee: #604532;
$colorLemonChiffon: #fffacd;
$colorLightGrey: #f5f5f5;
$colorLighterGrey: #f0f0f0;
$colorLinkWater: #cacbd0;
$colorMutedBlack: #4d5662;
$colorMutedDarkBlue: #1f1f2b;
$colorMutedGrey: #898989;
$colorOrange: #ff8801;
$colorPrimaryBlue: #4285f4;
$colorPrimaryBlueHover: #3498db;
$colorRed: #d92424;
$colorLightRed: #fff6f6;
$colorSecondaryBlue: #6699dd;
$colorShadowLight: #929eb8;
$colorShadow: #455b63;
$colorShadowLight: #929eb8;
$colorSidebarHeader: #323544;
$colorSilver: #bcbcbc;
$colorSilverGrey: #ebe8f7;
$colorSkyBlue: #f3f8ff;
$colorSolitude: #ece8f8;
$colorSummerSky: #186572;
$colorTransparent: transparent;
$colorWhite: #fff;
$colorWhiteSmoke: #f2f2f2;
$colorZambezi: #606060;
$colorMantis: #65c466;
$colorIron: #e9e9ea;
$colorLynch: #6b7a99;
$colorLynchDark: #454e63;
$colorHawkesBlue: #dddee1;
$colorRiverRed: #465064;
$colorPomegranate: #f44336;
$colorDeepRed: #ab3a38;
$colorIron: #dededf;
$colorDodgerBlue: #3f89f7;
$colorCeruleanBlue: #2e63b2;
$colorBaliHai: #919eb8;
$colorMischka: #e3e2e5;
$colorHalfBaked: #85b7d9;
$colorShipCove: #7c93bf;
$colorAthensGray: #eff1f5;
$colorMystic: #dee3ea;
$colorMysticLight: #d9d9d9;
$colorSpindle: #b6c6e6;
$colorLightWhite: #e7ecf0;
$colorDisabledGrey: #b5b7bf;
$colorDisabledLightGrey: #f4f5f8;
$colorPeriwinkleGray: #b6c6e5;
$colorCheese: #ffbf81;
$colorRockBlue: #929eb8;
$colorStiletto: #9f3a38;
$colorCornflowerBlue: #4285f4;
$colorPingDark: #bf9105;
$colorCatskillWhite: #f2f6f9;
$colorGeyser: #ccd4de;
$colorColumbiaBlue: #ccd4de;

//Status Colors
$colorStatusStopped: #d92424;
$colorStatusMoving: #2b9b56;
$colorStatusSpeeding: #ffeb3b;
$colorStatusIdle: #607d8b;
$colorStatusPowerOff: #00b7db;
$colorStatusHarshBraking: #d15b50;
$colorStatusCornering: #ba9600;
$colorStatusAssetCheck: #daa520;
$colorStatusTowing: #e91e63;
$colorStatusSuspended: #9c27b0;

// New status colors
$colorEventsStatusMoving: #4caf50;
$colorEventsStatusStopped: #f44336;
$colorEventsStatusIdle: #607d8b;
$colorEventsStatusSpeeding: #ffc107;
$colorEventsStatusAssetCheckIn: #ff9800;
$colorEventsStatusTowing: #e91e63;
$colorEventsStatusSuspended: #9c27b0;
$colorEventsStatusCornering: #ff5722;
$colorEventsStatusBraking: #ff5722;
$colorEventsStatusInput: #03a9f4;
$colorEventsStatusMoving: #4caf50;
$colorEventsStatusStopped: #f44336;
$colorEventsStatusIdle: #607d8b;
$colorEventsStatusSpeeding: #ffc107;
$colorEventsStatusAssetCheckIn: #ff9800;
$colorEventsStatusTowing: #e91e63;
$colorEventsStatusSuspended: #9c27b0;
$colorEventsStatusCornering: #ff5722;
$colorEventsStatusBraking: #ff5722;
$colorEventsStatusInput: #03a9f4;
