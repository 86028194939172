$fontAvenirNext: 'Avenir Next';
$primaryFontStack: $fontAvenirNext, Helvetica, Arial, sans-serif;

$fontWeightUltraLight: 100;
$fontWeightThin: 200;
$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightDemi: 600;
$fontWeightBold: 700;
$fontWeightHeavy: 800;

$fontSizeXXXXLarge: 72px;
$fontSizeXXXLarge: 50px;
$fontSizeXXLarge: 40px;
$fontSizeXLarge: 30px;
$fontSizeLarge: 24px;
$fontSizeXXMedium: 20px;
$fontSizeXMedium: 18px;
$fontSizeMedium: 17px;
$fontSizeNormal: 16px;
$fontSizeXNormal: 15px;
$fontSizeSmall: 14px;
$fontSizeSmaller: 13px;
$fontSizeXSmall: 12px;
$fontSizeXSmaller: 11px;
$fontSizeXXSmall: 10px;
