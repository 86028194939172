@import 'assets/stylesheets/resources/colors';

html,
body {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  font-family: inherit;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

a,
div,
button,
svg text {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
  background-color: #fff !important;
}

a {
  color: $colorCornflowerBlue;
}

a:hover {
  color: $colorCeruleanBlue;
}
