@import 'fontFaces/index';
@import 'modules/index';
@import 'resources/index';

html,
body {
  background-color: $colorWhite !important;
  color: $colorBlack;
  font-family: $primaryFontStack;
  font-size: $fontSizeNormal;
  margin: 0;
  min-width: 1300px;
  overflow: auto;
  padding: 0 !important;
}

input::placeholder {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: $colorBaliHai;
}

:focus-visible {
  outline: none;
}

.gm-style iframe + div {
  border: none !important;
}

.loading {
  .dimmed {
    &-grey {
      background-color: $colorAthensGray !important;
      color: $colorAthensGray !important;
      pointer-events: none;
      user-select: none;

      * {
        background-color: $colorAthensGray !important;
        color: $colorAthensGray !important;
        fill: $colorAthensGray !important;
        border: none;
        pointer-events: none;
        user-select: none;

        &::placeholder {
          color: $colorAthensGray !important;
        }
      }

      .react-time-picker__inputGroup__amPm {
        background: none;
      }

      img {
        visibility: hidden;
      }
    }

    &-hidden {
      visibility: hidden !important;
    }
  }
}
