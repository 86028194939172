@import url('https://fast.fonts.net/lt/1.css?apiType=css&c=c5a2fb6b-f415-4117-a2cf-090c02c3a267&fontids=721257,721263,721269,721275,721281,721287,1129850,1129856');
@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/Avenir/721257/2e3f5cb9-101f-46cf-a7b3-dfaa58261e03.woff2') format('woff2'),
    url('../../fonts/Avenir/721257/fa19948e-5e38-4909-b31e-41acd170d6f2.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/Avenir/1129850/64779e28-a3b8-4364-949c-771372a0a327.woff2') format('woff2'),
    url('../../fonts/Avenir/1129850/c6c8e4be-17eb-4475-bbfc-bb485ffde766.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/Avenir/1129856/49c5f3e1-5867-4b1d-a843-2d07fa60d85d.woff2') format('woff2'),
    url('../../fonts/Avenir/1129856/f401df6f-54eb-406c-b46a-90b9451c598d.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/Avenir/721263/2cd55546-ec00-4af9-aeca-4a3cd186da53.woff2') format('woff2'),
    url('../../fonts/Avenir/721263/1e9892c0-6927-4412-9874-1b82801ba47a.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/Avenir/721275/627fbb5a-3bae-4cd9-b617-2f923e29d55e.woff2') format('woff2'),
    url('../../fonts/Avenir/721275/f26faddb-86cc-4477-a253-1e1287684336.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/Avenir/721269/aad99a1f-7917-4dd6-bbb5-b07cedbff64f.woff2') format('woff2'),
    url('../../fonts/Avenir/721269/91b50bbb-9aa1-4d54-9159-ec6f19d14a7c.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/Avenir/721281/14c73713-e4df-4dba-933b-057feeac8dd1.woff2') format('woff2'),
    url('../../fonts/Avenir/721281/b8e906a1-f5e8-4bf1-8e80-82c646ca4d5f.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../../fonts/Avenir/721287/5c57b2e2-f641-421e-a95f-65fcb47e409a.woff2') format('woff2'),
    url('../../fonts/Avenir/721287/181c847e-cdbc-43d5-ae14-03a81c8953b4.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}
