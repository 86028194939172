@import "colors";

$height100: 100vh;
$subNavNavigationHeight: 53px;
$subNavVehiclesFiltersHeight: 151px;
$subNavGroupTitleHeight: 50px;

@mixin InputError {
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: $colorStiletto !important;
  box-shadow: none !important;
}
