@import 'assets/stylesheets/resources/fonts', 'assets/stylesheets/resources/colors';

.ErrorPage {
  text-align: center;

  &-title {
    color: $colorEbonyClay;
    font-size: $fontSizeXXLarge;
    font-weight: $fontWeightDemi;
    margin-top: 140px !important;
  }

  &-message {
    color: $colorEbonyClay;
    font-size: $fontSizeXMedium;
    font-weight: $fontWeightDemi;

    &--top {
      margin: 70px 0 40px 0;
    }

    &--bottom {
      margin: 90px 0 30px 0;
    }
  }

  &-links {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 40px;

    &Icon {
      width: 40px;
      height: 40px;
      fill: $colorPrimaryBlue;
    }

    &Text {
      color: $colorPrimaryBlue;
      font-size: $fontSizeXSmall;
      font-weight: $fontWeightDemi;
      margin-top: 10px;
    }
  }
}
