@import 'assets/stylesheets/resources/fonts';

@function is-important($important) {
  @return #{if($important, '!important', '')};
}

// Web H2 - 18px - Medium (500)
@mixin WebH218pxMedium {
  font-size: $fontSizeXMedium;
  font-weight: $fontWeightMedium;
}

// Web H2 (Bold) - 18px - DemiBold (600)
@mixin WebH218pxDemiBold {
  font-size: $fontSizeXMedium;
  font-weight: $fontWeightDemi;
}

// Web Title 1 - 16px - Medium (500)
@mixin WebTitle116pxMedium {
  font-size: $fontSizeNormal;
  font-weight: $fontWeightMedium;
}

// Web Title 1 (Alt) - 16px - DemiBold (600)
@mixin WebTitle1Alt16pxDemiBold {
  font-size: $fontSizeNormal;
  font-weight: $fontWeightDemi;
}

// Web Title 2 - 14px - Medium (500)
@mixin WebTitle214pxMedium {
  font-size: $fontSizeSmall;
  font-weight: $fontWeightMedium;
}

// Web Title 2 (Paragraph) - 14px - Medium (500)
@mixin WebTitle2Paragraph14pxMedium {
  font-size: $fontSizeSmall;
  font-weight: $fontWeightMedium;
}

// Web Title 2 (Italic) - 14px - Medium (500)
@mixin WebTitle2Italic14pxMedium {
  font-size: $fontSizeSmall;
  font-weight: $fontWeightMedium;
  font-style: italic;
}

// Web Title 2 (Alt) - 14px - DemiBold (600)
@mixin WebTitle2Alt14pxDemiBold {
  font-size: $fontSizeSmall;
  font-weight: $fontWeightDemi;
}

// Web Title 2 (Bold) - 14px - Bold (700)
@mixin WebTitle2Bold14pxDemiBold {
  font-size: $fontSizeSmall;
  font-weight: $fontWeightDemi;
}

// Web Body - 12px - Medium (500)
@mixin WebBody12pxMedium {
  font-size: $fontSizeXSmall;
  font-weight: $fontWeightMedium;
}

// Web LABEL - 12px - DEMIBOLD (600)
@mixin WebLABEL11pxDemiBold {
  font-size: $fontSizeXSmaller;
  font-weight: $fontWeightDemi;
  text-transform: uppercase;
}

// Web Body (Italic) - 12px - Medium (500)
@mixin WebBodyItalic12pxMedium {
  font-size: $fontSizeXSmall;
  font-weight: $fontWeightMedium;
  font-style: italic;
}

// Web Body (Alt) - 12px - DemiBold (600)
@mixin WebBodyAlt12pxDemiBold($important) {
  font-size: $fontSizeXSmall is-important($important);
  font-weight: $fontWeightDemi;
}

// Web Body (Bold) - 12px - Bold (700)
@mixin WebBodyBold12pxBold {
  font-size: $fontSizeXSmall;
  font-weight: $fontWeightDemi;
}

// Web Caption - 11px - Medium (500)
@mixin WebCaption11pxMedium {
  font-size: $fontSizeXSmaller;
  font-weight: $fontWeightMedium;
}
