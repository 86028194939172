@import 'assets/stylesheets/resources/fonts', 'assets/stylesheets/resources/colors';

.IntegrationConnectModal {
  .ConfirmationModal {
    &-title {
      font-size: $fontSizeXMedium;
      font-weight: $fontWeightDemi;
      color: $colorEbonyClay;
    }

    &-cancel {
      button {
        border: none;
      }
    }
  }

  &-text {
    font-weight: $fontWeightMedium;
    font-size: $fontSizeXSmall;
    color: $colorLynchDark;
  }

  &-Host,
  &-Username,
  &-Password {
    display: flex;
    align-items: center;
    margin-top: 20px;

    &--text {
      min-width: 70px;
      text-align: right;
      font-weight: $fontWeightDemi;
      font-size: $fontSizeXSmaller;
      color: $colorLynch;
      text-transform: uppercase;
    }

    &--input {
      margin-left: 10px;
      padding: 0;
      width: 200px;

      > input {
        padding: 10px;
        border-color: $colorPeriwinkleGray;
      }

      input {
        width: 200px;
      }
    }
  }

  &-Password {
    &--input {
      padding: 0 10px;
      border-color: $colorPeriwinkleGray;
      border-radius: 4px;

      > input {
        height: 34px;
      }
    }
  }
}

.IntegrationDisconnectModal {
  &-top {
    font-weight: $fontWeightMedium;
    font-size: $fontSizeSmall;
    color: $colorLynchDark;
  }

  &-bottom {
    font-weight: $fontWeightMedium;
    font-size: $fontSizeSmall;
    color: $colorLynchDark;
    margin-top: 20px;
  }

  .ConfirmationModal {
    &-content {
      background-color: transparent;
      padding: 0 20px;
      margin: -10px 0;
      font-weight: $fontWeightMedium;
      font-size: $fontSizeSmall;
      line-height: 19px;
      color: $colorLynchDark;
    }

    &-cancel {
      button {
        border: none;
      }
    }
  }
}
